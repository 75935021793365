@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Black.eot');
    src: url('../font/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Black.woff2') format('woff2'),
        url('../font/SourceSansPro-Black.woff') format('woff'),
        url('../font/SourceSansPro-Black.ttf') format('truetype'),
        url('../font/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-ExtraLight.eot');
    src: url('../font/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('../font/SourceSansPro-ExtraLight.woff') format('woff'),
        url('../font/SourceSansPro-ExtraLight.ttf') format('truetype'),
        url('../font/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-SemiBold.eot');
    src: url('../font/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('../font/SourceSansPro-SemiBold.woff') format('woff'),
        url('../font/SourceSansPro-SemiBold.ttf') format('truetype'),
        url('../font/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Regular.eot');
    src: url('../font/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Regular.woff2') format('woff2'),
        url('../font/SourceSansPro-Regular.woff') format('woff'),
        url('../font/SourceSansPro-Regular.ttf') format('truetype'),
        url('../font/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Light.eot');
    src: url('../font/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Light.woff2') format('woff2'),
        url('../font/SourceSansPro-Light.woff') format('woff'),
        url('../font/SourceSansPro-Light.ttf') format('truetype'),
        url('../font/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../font/SourceSansPro-Bold.eot');
    src: url('../font/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/SourceSansPro-Bold.woff2') format('woff2'),
        url('../font/SourceSansPro-Bold.woff') format('woff'),
        url('../font/SourceSansPro-Bold.ttf') format('truetype'),
        url('../font/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Goldman';
    src: url('../font/Goldman-Regular.eot');
    src: url('../font/Goldman-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/Goldman-Regular.woff2') format('woff2'),
        url('../font/Goldman-Regular.woff') format('woff'),
        url('../font/Goldman-Regular.ttf') format('truetype'),
        url('../font/Goldman-Regular.svg#Goldman-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goldman Sans';
    src: url('../font/GoldmanSans-Regular.eot');
    src: url('../font/GoldmanSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/GoldmanSans-Regular.woff2') format('woff2'),
        url('../font/GoldmanSans-Regular.woff') format('woff'),
        url('../font/GoldmanSans-Regular.ttf') format('truetype'),
        url('../font/GoldmanSans-Regular.svg#GoldmanSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
strong,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0; font-family: inherit; font-size: 100%; font-style: inherit; font-weight: inherit;
    margin: 0; outline: 0; padding: 0; vertical-align: baseline;
}
body{ 
	background: #fff; font-size: 16px; line-height: 1.3; font-family: 'Source Sans Pro'; font-weight: normal; 
	font-style: normal; color:#42425c; overflow-x: hidden; word-wrap: break-word; 
	-webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
}
:focus {
    outline: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.clear,
.clearfix {
    clear: both;
}
.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-weight: 700;
}
.clear{ clear:  both;}
a{ color: #051f3a; transition: all 400ms; -webkit-transition: all 400ms; cursor: pointer; outline: none; text-decoration: none;}
a:hover{ color: #f9951d; outline: none; text-decoration: none;}
a:focus, a:active{color: inherit; outline: none; text-decoration: none;}
img {max-width: 100%; height: auto; vertical-align: top;}
p{ margin: 0 0 15px;}  
ol, ul {list-style: none; margin: 0; padding: 0px;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    margin: 0px 0px 25px 0px; padding: 0px; font-weight: 600; line-height: 1.2; color: #051f3a;
}

.account-wrapper {
    width: 600px;  position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%, -50%);
    padding: 70px; border-radius: 6px;  background-color: #fff;  box-shadow: 1px 0 36px rgba(0,0,0,0.14);
}
.account-title-warpper h2.title {text-align: center;  text-transform: uppercase; font-weight: 600;}
.account-forgot-psw{margin-bottom: 10px;}
.loading{ position: relative; min-width: 100px; min-height: 100vh;}
.loading:after {
  content: ""; background: url(../images/loading-pg.gif) center/100px no-repeat #fff;
  background-size: 100%; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border-radius: 40px;
}
.loading_data{ min-width: initial; min-height: initial;}
#map { position: absolute; top: 0; bottom: 0; width: 100%; }
.mapboxgl-marker {
    width: 25px;
    height: 25px;
    /*border:1px solid gray;
    background-color:lightblue;*/
}
.btn span.load-data
{
    position: absolute !important;
    top: -2px;
    left: 0;
    width: 100%;
    height: 50px;
    display: inline-block;
    color: #f15922;
    background: url(../images/loading-pg.gif) 47px center no-repeat #fff;
    background-size: 30px;
    -webkit-background-size: 30px;
    line-height: 50px;
    padding-left: 30px;
}

.add_button button {float: right;}
a.templateedit {
    margin: 5px;
}

/*--- Btn--- */
input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button,
.btn {
	min-width: 160px;
	height: 48px;
    color: #fff;
    display: inline-block;
    border-radius: 0px;
    margin: 0px 0px 15px;
    padding: 12px 25px;
    cursor: pointer;
    background: #051f3a;
    border: 0px;
    font-size: 18px;    
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
	transition: all 400ms; -webkit-transition: all 400ms;
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus{outline: none;}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover,
.btn:hover {
    color: #fff;
    background: #051f3a;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select,
.form-control {	
    padding: 12px 20px;
    border: 1px solid #051f3a5e;
    width: 100%;
    margin: 0 0 15px;
    background: #fff;
    border-radius: 0px;
    height: 48px;
    font-size: 16px;
    color: #051f3a;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
	border-radius: 0px;
}
textarea{ resize: none; outline: 0;}
/*--- table--- */
table{ width: 100%; max-width: 100%; margin-bottom: 20px; }
table td, table th {padding: 10px; vertical-align: middle;}

/* ---  Header --- */
.header {
    position: fixed;
    width: 100%;
    top: 0;
	right: 0;
    left: 0;
    height: 55px;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
	background: #fff;
	-webkit-transition: .3s ease all;
    transition: .3s ease all;
	border-bottom: 1px solid #b7b7b7;
}
.navbar-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;	
    align-items: center;
}
.logo-box {
    height: 55px;
    width: 240px;
	background: #051f3a;		
    position: relative;
    padding: 10px 10px 10px;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;	
    border-bottom: 1px solid #051f3a;
	text-align: center;
}
.logo-box a{font-size:32px; font-weight:bold; color:#fff;}
.header-cnt {
    display: flex; height: 55px; width: calc(100% - 240px); align-items: center;
	padding: 0px 30px 0px 10px;	justify-content: space-between;	transition: .3s ease all;
}
.logo-box .logo-sm {display:none; transition: 0.3s;}
.logo-box .logo-lg{display:block; transition: 0.3s;}
.logo-box.active {width: 75px;}	
.logo-box.active + .header-cnt {width: calc(100% - 75px);}
.logo-box.active .logo-sm {display:block;}
.logo-box.active .logo-lg {display:none;}	
.header .menu-icon {display: inline-block; width: 22px;}
.header .menu-icon span { background-color: #42425c; display: block; height: 2px; margin-bottom: 4px; transition: all .5s;}
.header .menu-icon span:last-child{margin-bottom:0px;}

.menu-icon.active .icon_menu1 {
	-webkit-transform: translateY(14px) rotate(45deg) scale(1); transform: translateY(8px) rotate(45deg) scale(1); 
}
.menu-icon.active .icon_menu2 {
	-webkit-transform: translate(0) rotate(0deg) scale(0); transform: translate(0) rotate(0deg) scale(0); 
}
.menu-icon.active .icon_menu3 {
	-webkit-transform: translateY(-8px) rotate(-45deg) scale(1); transform: translateY(-4px) rotate(-45deg) scale(1); 
}
.header .r-icon{font-size: 20px; position:relative; color: #42425a; margin-right: 30px;}
.header .bedge {
    background: #f3a00a; display: inline-block; width: 20px; height: 20px;
    border-radius: 100px; font-size: 14px; position: absolute; top: -7px;
    left: 10px; text-align: center; line-height: 20px;
}
.header .notity-icon.r-icon .bedge {background: #FF7588;}
.header-cnt-left {display: flex; align-items: center;}
.search-box {margin-left: 20px; position: relative;}
.search-box .form-control {
    padding: 12px 22px 12px 44px; border: 1px solid #b0b0b0; width: 100%; margin: 0 0 0px; background: #fff;
    height: 44px; font-size: 16px; color: #42425c; border-radius: 10px;
}
.search-icon {
    display: inline-block; position: absolute; top: 1px; background: #fff; left: 1px; border-radius: 10px;
    padding: 9px 9px 3px;
}
.myaccount {margin-top: 8px; position: relative;}
.myaccount-inner {display: flex;}
.myaccount-inner .user-name {font-size: 18px; display: inline-block; margin-right: 15px;}
ul.user-menu {
    top: 55px; right: 20px; width: 200px; height: 140px; padding: 15px; background: #FFFFFF;
    box-shadow: 3px 3px 3px #00000029; border: 1px solid #dedede; border-radius: 15px;
    opacity: 0; visibility: hidden; position: absolute;
}
ul.user-menu.active {opacity: 1; visibility: visible;}
ul.user-menu li {padding: 8px 0px;}
ul.user-menu li a{position: relative; padding-left: 35px;}
ul.user-menu .icon {position: absolute; left: 0px; top: -2px;}
.icon{background-size:contain; background-repeat: no-repeat !important; height:30px; width:30px; display: inline-block;}
.header-cnt .icon{height:22px; width:22px;}
.sc-icon { background: url(../images/Search.svg); background-size:contain;}
.ck-icon { background: url(../images/Alarm.svg); background-size:contain;}
.a-icon { background: url(../images/Alert.svg); background-size:contain;}
.u-icon { background: url(../images/User.svg); background-size:contain;}
.profile-icon { background: url(../images/Profile.svg); background-size:contain;}
.color-icon { background: url(../images/Color.svg); background-size:contain;}
.logout-icon { background: url(../images/Logout.svg); background-size:contain;}


.dashboard-icon{ background: url(../images/Dashboard.svg); background-size:contain; opacity: 0.8;}
.file-icon{ background: url(../images/Files.svg); background-size:contain;}
.docs-icon{ background: url(../images/Documents.svg); background-size:contain;}
.letter-icon{ background: url(../images/Letters.svg); background-size:contain;}
.comm-icon{ background: url(../images/Communications.svg); background-size:contain;}
.ac-icon{ background: url(../images/Accounts.svg); background-size:contain;}
.setting-icon{ background: url(../images/Settings.svg); background-size:contain; opacity: 0.8;}
.history-icon{ background: url(../images/history.svg); background-size:contain;}
.font-icon{ background: url(../images/font-1.svg); background-size:contain; opacity: 0.8;}
.payment-icon{ background: url(../images/payment.svg); background-size:contain; height: 35px; width: 35px;}
.userlist-icon{ background: url(../images/user-list.svg); background-size:contain; opacity: 0.8;}
.driver-icon{ background: url(../images/driver.svg); background-size:contain; height: 34px; width: 34px;}
.company-icon{ background: url(../images/company1.svg); background-size:contain;}
.notification-icon{ background: url(../images/notifications.png); background-size:contain;}

.vehicletype-icon{ background: url(../images/van.svg); background-size:contain;}
.vehicle-icon{ background: url(../images/car.svg); background-size:contain;}
.completed-icon{ background: url(../images/completed.svg); background-size:contain;}
.incompleted-icon{ background: url(../images/incompleted.svg); background-size:contain;}
.locate-icon{ background: url(../images/road.svg); background-size:contain;}
.template-icon{ background: url(../images/email.svg); background-size:contain;  height: 30px; width: 30px; opacity: 0.8;}
.cms-icon{ background: url(../images/cms.png); background-size:contain;}
.quote-icon{ background: url(../images/quote.png); background-size:contain;  height: 30px; width: 30px;}


.img_class{height:90px;}

/* ---- Sidebar Menu ---- */
.sidebar-menu {
    background: #051f3a;
    position: fixed;
    top: 55px;
    height: calc(100% - 55px);
    width: 240px;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 1px 0 20px #051f3a17;
    z-index: 1;
}
.sidebar-menu .main-menu {padding: 30px 0px; height:100%;}
.menulist {
	list-style: none; 
	padding: 0; 
	margin: 0;
}
.menulist > li {
    margin: 10px 0px;
	margin-left: -20%;
    position: relative;
    animation: slidein 500ms forwards;
}
.menulist > li > a {
    padding: 18px 15px 18px 60px;
    position: relative;
    display: block;
    color: #fff;
    white-space: nowrap;
    font-size: 18px;
}
.menulist > li > a:hover, .menulist > li.active > a {
    background: #041629;
}
.sidebar-menu.menu-close .menulist > li > a {
    padding: 18px 15px 18px 50px;
}
.menulist > li > a > i {position: absolute; left: 15px; top: 0px; bottom: 0; margin: auto 0;}

.sidebar-menu .submenu.dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
	width: auto;
    padding: 10px 0px;
    margin: -1px;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #051f3a;
    background-clip: padding-box;
    border: 1px solid transparent;
	border-radius: 0px 15px 15px 0px;
}

.sidebar-menu .submenu a {
	color: #fff; 
    display: block;
    padding: 10px 15px;
}
.sidebar-menu.menu-close .submenu {
	position: absolute !important;
    border: 4px;
    left: 30px !important;
    top: 0 !important;
    transform: none !important;
	border-radius: 4px;
}
.sidebar-menu.menu-close .submenu:before {
    content: "";
    border: 10px solid #f8931e;
    border-color: transparent #f8931e transparent transparent;
    position: absolute;
    left: -20px;
    top: 7px;
}
.sidebar-menu .submenu a:hover {
    background: #041629; color: #fff;
}
.menu-close.sidebar-menu {width: 75px;}
.menu-close .dropdown-toggle::after {
    display: none;
}
.menu-close.sidebar-menu  + .content{
    margin-left: 75px;
}
.menulist > li > a > span {
    display: inline-block;
    opacity: 1;
    transform: translateX(0px);    
    visibility: visible;
}

.menu-close.sidebar-menu .menulist li a span {    
    visibility: hidden;
	opacity: 0;	
	transform: translateX(60px);
}


	@-webkit-keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}

	@-moz-keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}
	 @-o-keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}
	 @keyframes slidein {
		from {
			margin-left: -10%;
		}

		to {
			margin-left: 0%;
		}
	}
	
.menulist > li:nth-child(1){animation-delay: 0.40s; }
.menulist > li:nth-child(2){animation-delay: 0.48s; }
.menulist > li:nth-child(3){animation-delay: 0.56s; }
.menulist > li:nth-child(4){animation-delay: 0.64s; }
.menulist > li:nth-child(5){animation-delay: 0.72s; }
.menulist > li:nth-child(6){animation-delay: 0.80s; }
.menulist > li:nth-child(7){animation-delay: 0.88s; }


/* ---- Content Area ---- */ 

.content {
    padding: 0;
    position: relative;
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-left: 240px;
    overflow-x: hidden;
	margin-top: 55px;
}
.form-wrapper {padding: 60px 80px;}
.form-row { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 60px;}
.form-left, .form-right {width: 46%;}
.form-group {margin: 0 0 10px;}
.form-group label {margin-bottom: 15px; display: inline-block; color: #051f3a;}
.form-group textarea.form-control {height: 100px; resize: none; }
.title{padding-bottom: 15px; text-align: center; position: relative;}
.title h4, .title h3{ font-size:30px; font-weight: bold; margin: 0px 0px 15px 0px;}
.btn-group.export {text-align: center;}
.add_button .add {border-bottom: 1px solid #051f3a; color: #051f3a; line-height: 1;}
.listing-header .add_button a{font-size: 18px; font-weight: 600;}
.status_sucess {
    left: 0; right: 0; top: 10%; text-align: center; background-color: #ffff;
    border-color: #d6e9c6; padding: 45px 15px; margin-bottom: 20px; border: 1px solid transparent;
    border-radius: 4px; position: absolute;  margin: auto;  width: 55%; min-height: 120px; z-index: 9999;
    -webkit-box-shadow: 8px 8px 41px 2px rgba(214,214,214,1);
    -moz-box-shadow: 8px 8px 41px 2px rgba(214,214,214,1);
    /*box-shadow: 8px 8px 41px 2px rgba(214,214,214,1);*/
}
.status_sucess h3{
    margin: 0px 0px 0px 0px; padding: 0px; font-weight: 600; line-height: 1.2;
    color: #051f3a; font-size: 28px;
}
p.error-txt {text-align: left; margin:0;}
.status_sucess .close {
    position: absolute; right: 10px; font-size: 20px; font-weight: 600; top: 10px; padding: 2px 4px; cursor: pointer;
}
.custom-scroll {height: 150px; overflow: auto;}
.download {text-align: center; margin-top: 10px;}
.download a {font-size: 18px; font-weight: 600;}

.form-row.import-user-record {justify-content: center;}
.car-listing-wrap {padding: 70px 90px;}
table {border-collapse: collapse;  width: 100%;} 
table td, table th {border: 1px solid #ddd;}  
table tr:nth-child(even){background-color: #f2f2f2;}
table tr:hover {background-color: #ddd;}  
table th {
    padding-top: 12px;  padding-bottom: 12px;  text-align: left; background-color: #051f3a; color: #fff;
}

.listing-header {display: flex; justify-content: space-between;}
.choose-file, .form-group.vehicles-select{ margin-bottom: 25px;}
.change-password-wrap.account-wrapper {
    width: 600px; padding: 70px;  border-radius: 6px; background-color: #fff;
    box-shadow: none; margin: auto; position: relative; top: unset; left: unset; transform: none;
}
.form-group.eye-pad {position: relative;}
.form-group.eye-pad span {position: absolute; top: 48px; right: 15px;}
.back.list-page {
    position: absolute; top: 10px; left: 0; border-bottom: 1px solid #051f3a94;
    line-height: 1; font-size: 18px;
}
.errorspan {color: #ff0000;}
.actiongroup{ text-align: center;}

ul.carimagelisting {display: flex; flex-wrap: wrap;}
ul.carimagelisting li.thumb {
    display: flex;
    align-items: center;
    width: 23%;
    margin: 1% 1%;
    border: 1px solid #e2dddd;
    position: relative;
    padding: 5px;
}
ul.carimagelisting .thumb input{
    margin: 0 5px 0 0;
}
ul.carimagelisting .thumb img {
    height: 60px;
}
ul.carimagelisting .thumb a {
    position: absolute;
    right: 0;
    top: 0;
    background: #051f3a30;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 12px;
}
.carimagelisting .thumb input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}
.carimagelisting .thumb span {
    position: relative;
    padding: 0 0 0 35px;
    pointer-events: none;
    display: block;
}
.carimagelisting .thumb span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 22px;
    height: 22px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 20px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}
.carimagelisting .thumb input[type=radio]:checked + span:before {    
	background-image: url(../images/check.svg);	
    background-color: #051f3a;
    background-position: 4px 6px;
    background-size: 12px;
    z-index: 1;
    border: 1px solid #051f3a;
    background-repeat: no-repeat !important;
    height: 22px;
    width: 22px;
    display: inline-block;
}

.actiongroup a {display: inline-block; margin: 0px 2px; font-size: 18px;}
.drivers-deatils .form-group {margin: 0 0 30px; display: flex; align-items: center;}
.drivers-deatils .profile-photo img {height: 70px; margin-left: 15px;}
.drivers-deatils .driver-icon img {height: 40px; margin-left: 10px;}
.listing-header .add_button {padding-top: 10px;}
.custom-scroll-wrap {height: calc(100% - 0px) !important;}
.site-bg-clr {display: flex; align-items: center;}
.site-bg-clr label {margin-bottom: 0px; margin-right: 10px;}
table input[type="text"]{padding: 10px 20px; margin: 0 0 0px; height: 40px;}


.search-inner {position: relative; height: 42px;}
.search-inner input[type="text"]{margin: 0 0 0px; height: 100%; padding: 10px 45px 10px 20px; font-size: 18px;}
.search-inner button {
    margin: 0 0 0px; background-image: url(../images/search1-white.svg); background-size: contain;
    background-color: #051f3a; background-repeat: no-repeat; min-width: auto; font-size: 0;
    background-size: 20px 20px; height: 100%; position: absolute; background-position: center;
    right: 0; padding: 10px 22px;
}
.pagination {display: inline-block; padding-left: 0; margin: 20px 0; border-radius: 4px;}
.pagination li {display: inline;}
.pagination li a {
    position: relative; float: left; padding: 6px 12px; line-height: 1.4; text-decoration: none; color: #337ab7;
    background-color: #fff; border: 1px solid #ddd; margin-left: -2px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    z-index: 3; color: #fff; background-color: #337ab7; border-color: #337ab7; cursor: default;
}
.text-center {text-align: center;}
.map-wrapper {height: 600px; position: relative;}
#map {position: absolute; top: 0; bottom: 0; width: 100%; height: 100%;}
.ongoing-wrapper.form-row {padding: 30px 30px 20px; margin-bottom: 40px; align-items: center; border: 1px solid #d8d8d8;}
.ongoing-wrapper.form-row .form-left {border-right: 1px solid #d8d8d8;}
.neardrive-wrapper {max-height: 300px; overflow-y: auto;}
.neardrive-wrapper h3 {font-size: 22px; font-weight: 700; margin: 0px 0px 15px 0px;}
.neardrive-wrapper li {line-height: 1.4; margin-bottom: 5px;}
.confirm-action {
    opacity: 0;
    visibility: hidden;
    max-width: 700px;
    height: 170px;
    border: 1px solid #fff;
    background: #fff;
    padding: 25px 35px 35px 35px;
    margin-bottom: 25px;
    text-align: center;
    position: fixed;
    z-index: 9;
    left: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    top: 0;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 14%);
    transition: 0.6s all ease;
}
.confirm-action.show {
    opacity: 1;
    visibility: visible;
    animation: slideInFromRight 600ms forwards;
}
.confirm-action p {
    font-size: 24px;
}
.confirm-action .btn {
    margin-top: 10px;
    margin-right: 20px;
    width: 100px;
    font-size: 26px;
    border-radius: 0px !important;
    height: 52px !important;
    line-height: 48px !important;
    border: 2px solid #000000 !important;
    font-weight: 700 !important;
    color: #000 !important;
    padding: 0px 10px !important;
    display: inline-block;
    text-align: center;
    transition: 0.3s all ease;
        background: #fff;
            position: relative;
}
.confirm-action .btn:hover {
    color: #fff !important;
    background: #000 !important;
}
.data-picker {
    display: flex;
    margin-left: 2%;
}
.data-picker input[type="text"] {
    width: 97%;
    height:42px;
}
.data-picker input[type="text"] {
    margin: 0 0 0px;
    padding: 10px 45px 10px 20px;
    font-size: 18px;
}
.payment-add-button{
    width:10%;
    margin-left:10px;
}
.data-picker .react-datepicker__navigation--previous {
    left: -30px;
}
.data-picker .react-datepicker__navigation--next {
    right: -30px;
}
.data-picker button:hover, .btn:hover {
   
    background: transparent;
    color: #051f3a;
    border: 2px solid #051f3a;
}
.data-picker .button{
    border: 2px solid #051f3a;
}
.data-picker .react-datepicker-popper[data-placement^="bottom"]{
    padding-top:0px;
    /* transform: translate(228px, 123px) !important; */
}

.default-point {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 42px;
}
.default-point input[type="radio"] {
    width: 41px;
    height: 12px;
}
.font-section .css-yk16xz-control{
    border-color: hsl(0,0%,70%);
    border-radius: 0px;
}
.font-section .css-1okebmr-indicatorSeparator{
    background-color: hsl(0,0%,70%);
}
.font-section .css-tlfecz-indicatorContainer{
    color: hsl(0,0%,70%);
}
.smpt-mail-function input[type="checkbox"] {
    width: 22px;
    height: 20px;
    /* line-height: 36px; */
    position: relative;
    top: 4px;
}

/* .domain-url-function button{
    color: white;
    padding: 28px !important;
    min-width: 47px;
    height: 69px !important;
}
.domain-url-function button i{
    font-size:20px;
   
}
.domain-url-function .fa-remove:before, .domain-url-function .fa-close:before, .domain-url-function .fa-times:before {
    content: "\f00d";
    position: relative;
    top: -4px;
} */

@media(min-width:1400px){
	body{ font-size:28px;}
    .header {height: 75px;}
    .myaccount-inner .user-name {font-size: 28px; }
	.logo-box {height: 75px; width: 340px;}
	.logo-box a{font-size: 36px; line-height: 45px;}	
	.header-cnt {height: 75px; width: calc(100% - 340px);}
	.search-box .form-control {padding: 12px 22px 12px 62px; height: 60px; font-size: 28px;}
	.search-icon {top: 2px; left: 2px; padding: 12px 10px 2px;}
	.sidebar-menu {top: 75px; height: calc(100% - 70px); width: 340px;}
	.icon {width: 40px; height: 40px;}
    .header-cnt .icon{height:33px; width:33px;}
    ul.user-menu {top: 75px; width: 300px; height: 210px;}    
    ul.user-menu li {padding: 8px 0px;}
    ul.user-menu li a {padding-left: 50px; font-size: 28px; }
    ul.user-menu .icon {top: 0px; }
	.menulist > li > a {padding: 23px 15px 23px 70px; font-size: 28px;}
	.content {margin-left: 340px; margin-top: 75px;} 
	
	/*--Form Wrapper--*/
	.form-wrapper {padding: 80px 120px;}
	.form-wrapper .form-control {padding: 12px 25px; height: 68px; font-size: 28px;}
	.title h4, .title h3{ font-size:42px;}
    .car-listing-wrap {padding: 90px 120px;}
    a.back.list-page {font-size: 18px;}
	.listing-header .add_button a {font-size: 28px;}
	.search-inner {height: 52px;}
	.search-inner input[type="text"] {padding: 10px 50px 10px 20px; font-size: 28px;}
	.search-inner button {background-size: 24px 24px; padding: 10px 25px;}
	.pagination li a {padding: 6px 18px;margin-left: -2px;}
	
}	
@media(max-width:1199px){
	.form-wrapper, .car-listing-wrap {padding: 40px 30px;}
	.title h4, .title h3 {font-size: 26px;}
	.form-left, .form-right {width: 48%;}
    .payment-add-button {
        width: 20%;
        margin-left: 10px;
    }
	
}
@media(max-width:991px){

.btn {min-width: 140px; padding: 8px 24px; height: 42px;}
.header {height: 60px;}
.logo-box.mobi-close {display: none;}	
.mobi-logo{display:block;}	
.header-cnt {width: calc(100% - 0px);}
table.table.table-striped.table-bordered.table-hover {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}


.sidebar-menu {top: 60px; height: calc(100% - 60px);}
.sidebar-menu .main-menu {padding: 15px 0px;}
.mobi-close.sidebar-menu {width: 75px;}
.mobi-close.sidebar-menu .menulist li a span {visibility: hidden; opacity: 0; transform: translateX(60px);}	
.mobi-close.sidebar-menu + .content{margin-left: 75px;}
.mobi-close.sidebar-menu .dropdown-toggle::after {display: none;}

.mobi-close.sidebar-menu .submenu {
	position: absolute !important; border: 4px; left: 30px !important; top: 0 !important; 
}
.mobi-close.sidebar-menu .submenu:before {
    content: ""; border: 10px solid #f8931e; border-color: transparent #f8931e transparent transparent;
    position: absolute; left: -20px; top: 7px;
}	
.mobi-close.menu-close.sidebar-menu {width: 240px;}
.mobi-close.menu-close.sidebar-menu .menulist li a span {    
    visibility: visible; opacity: 1; transform: translateX(0px);
}
.mobi-close.menu-close.sidebar-menu .submenu.show {
    position: static !important; left: 0 !important; top: 0 !important;
}
.mobi-close.menu-close.sidebar-menu .dropdown-toggle::after {display: inline-block;}
.mobi-close.menu-close.sidebar-menu .submenu:before {
    border-color: transparent transparent transparent transparent;
}
.dropdown-menu {font-size: 15px;}
.logo-box.active + .header-cnt {width: calc(100%);}
.menulist > li > a {font-size: 16px;}

}
@media(max-width:768px){
.listing-header{
  display:block;
  margin-bottom: 20px;
}
.search-list {
    margin-bottom: 15px;
}
.data-picker input[type="text"] {
    width: 99%;
    height: 42px;
}
.data-picker .react-datepicker-wrapper {
 width:100%
}
.listing-header {
    display: block;
    justify-content: space-between;
}
.data-picker {
    display: flex;
    margin-bottom: 3%;
    margin-left:0%;
}
.data-picker .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 10px;
    /* transform: translate(26px, 153px) !important; */
}
.data-picker input.form-control.react-datepicker-ignore-onclickoutside {
    margin-left: 0px;
}
.payment-add-button {
    width: 100%;
    margin-left: 10px;
}
.domain-url-function input[type="text"] {
    width: 77% !important;
}
}
@media(max-width:600px){
.data-picker input[type="text"] {
    width: 100%;
    height: 42px;
}
.form-left, .form-right {
    width: 100%;
}
.form-left input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea, select, .form-control{
    margin:0 0 0px;
}
.form-right input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea, select, .form-control{
    margin:0 0 0px;
}
.listing-header .add_button {
    padding-top: 10px;
    text-align: center;
}
.data-picker {
    display: block;
}
.data-picker input.form-control.react-datepicker-ignore-onclickoutside {
    margin-left: 0px;
   
}
.data-picker input[type="text"] {
    margin-bottom:10px
}
.data-picker .react-datepicker-popper[data-placement^="bottom"] {
    padding-top:0px
}


}
@media(max-width:480px){		
.menu-icon.active .icon_menu1 {transform: translateY(8px) rotate(45deg) scale(1);}
.mobi-close.sidebar-menu {width: 55px;}
.menulist > li > a > i {left: 11px; top: 0px; width: 30px; height: 24px; line-height: 24px; font-size: 22px;}
.menulist > li > a {padding: 12px 15px 12px 50px;}
.sidebar-menu .submenu.show {margin: 0px 10px 0px 40px;}
.mobi-close.sidebar-menu + .content {margin-left: 55px;}

}
/* .domain-url-function input[type="text"]{
  width:70%;
} */
.marker {
  background-image: url('../images/Ovel.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.mapboxgl-ctrl-group button {
    width: 36px;
    height: 32px;
    min-width: 36px;
    margin: 0px 0px 0px;
}

.samplefont p{
	margin: 15px 0 15px;
    font-size: 30px;
    font-weight: 600;
}

/*********************view*********************/


.details_left{
    width:100%;
}
.view-details{
    width:100%;
    display: flex;
}
.view-details-one{
    width:25%;
}
.view-details-two{
    width:73%;
    margin-left:2%;
}
.details_left p{
    width: 100%;
    float: right;
    color: #051f3a;
}
.details_left p span{
    float: right;
}
.details_right{
    width:100%;
}

.direct-payment-inner {
    width: 100%;
}
